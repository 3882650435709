import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';

import { MatTableDataSource } from '@angular/material/table';
import { UiService } from 'src/app/utils/ui.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActiveLicence, Licence, SvKey } from 'src/app/admin/interfaces/licence.interface';
import { LicencesService } from 'src/app/admin/services/licences.service';
import { UsersService } from 'src/app/admin/services/users.service';
import { EditKeyNameDialogComponent } from './edit-key-name-dialog/edit-key-name-dialog.component';
import { Roles } from 'src/app/admin/interfaces/user.interface';
import { RoleConstants } from '@smartviser/core-lib';
import { AddKeyDialogComponent } from './add-key-dialog/add-key-dialog.component';
import { PickLicenceDialogComponent } from './pick-licence-dialog/pick-licence-dialog.component';
import { uiKeyUpdate, uiKeyUpdateError } from 'src/app/utils/constants';
import { MatPaginator } from '@angular/material/paginator';
import { ValidDialogComponent } from 'src/app/utils/shared-dialog/valid-dialog.component';
import { Sort } from '@angular/material/sort';

@Component({
  selector: 'app-key-mnagement',
  templateUrl: './key-management.component.html',
  styleUrls: ['./key-management.component.less']
})
export class KeyManagementComponent implements OnInit, OnChanges {
  @Input() customerIdInput!: number;
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  displayedColumns: string[] = [
    'Id',
    'Key',
    'Number',
    'Nickname',
    'User',
    'MSISDN',
    'Licence',
    'Actions'
  ];

  svKeysList: MatTableDataSource<SvKey> = new MatTableDataSource<SvKey>([]);
  isLoading: boolean = true;
  customerId!: number;
  activeKey: ActiveLicence[] = [];
  activeLicence: ActiveLicence[] = [];
  search: string = '';
  svKeys: SvKey[] = [];

  constructor(
    private licencesService: LicencesService,
    private usersService: UsersService,
    private uiService: UiService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    // If AdminSv or AdminSystem display User of customer instead dispaly user of the customer
    if (this.customerIdInput) {
      this.customerId = this.customerIdInput;
    } else {
      this.customerId = this.usersService.getCustomerId();
    }
    if(this.customerIdInput !== this.customerId){
      this._setSvKey();
      this._setActiveLicences();
      this._setActiveSvKey();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.customerIdInput) {
      this.svKeysList.data = [];
      this.customerId = changes.customerIdInput.currentValue;
      this._setSvKey();
      this.search = '';
    }
  }

  sortData = (sort: Sort): void => {
    const isAsc: boolean = sort.direction === 'asc';
    const sortedList: SvKey[] = this.svKeys.sort((a: SvKey, b: SvKey) => {
      switch (sort.active) {
        case 'Id':
          return this._compareKeys(a.id, b.id, isAsc);
        case 'Key':
          return this._compareKeys(a.type.type, b.type.type, isAsc);
        case 'Number':
          return this._compareKeys(a.number, b.number, isAsc);
        case 'Nickname':
          return this._compareKeys(a.simnickname, b.simnickname, isAsc);
        case 'User':
          return this._compareKeys(a.user?.name as string, b.user?.name as string, isAsc);
        case 'MSISDN':
          return this._compareKeys(a.msisdn, b.msisdn, isAsc);
        case 'Licence':
          return this._compareKeys(a.licence?.id as number, b.licence?.id as number, isAsc);
        default:
          return 0;
      } 
    });
    this.svKeysList = new MatTableDataSource(sortedList);
    this.svKeysList.paginator = this.paginator;
  };

  _compareKeys = (a: number | string, b: number | string, isAsc: boolean): number => 
    (a < b ? -1 : 1) * (isAsc ? 1 : -1);

  applyFilter(event: Event): void {
    const filterValue: string = (event.target as HTMLInputElement).value;
    this.svKeysList.filter = filterValue.trim().toLowerCase();
  }


  deleteSvKey(svKey: SvKey): void {
    const dialogRef: MatDialogRef<ValidDialogComponent> =  this.dialog.open(ValidDialogComponent, {
      minWidth: 600
    });
    dialogRef.afterClosed().subscribe((shouldDelete: boolean) => {
      if(shouldDelete){
        this.isLoading = true;
        this.licencesService.deleteSvKeys(this.customerId, svKey.id).subscribe({
          next: () => {
            const indexToDelete: number = this.svKeysList.data.findIndex((sk: SvKey) => sk.id === svKey.id);
            this.svKeysList.data.splice(indexToDelete, 1);
            this.svKeysList._updateChangeSubscription();
            this.uiService.openSnackBar('Key deleted', undefined);
            
          },
          error: () => {
            this.uiService.openSnackBar('Error deleting sv Key', undefined);
          },
          complete: () => {
            this.isLoading = false;
          }
        });
      }
    });
  }

  addSvKey(): void{
    const dialogRef: MatDialogRef<AddKeyDialogComponent> = this.dialog.open(
      AddKeyDialogComponent,
      {
        minWidth: 600,
        data: this.customerId
      }
    );
    dialogRef.afterClosed().subscribe((svKeyEdit: SvKey) => {
      if (svKeyEdit) {
        // Check if key already exists
        if(this.svKeysList.data.some((svK: SvKey) => svK.number === svKeyEdit.number && svK.type === svKeyEdit.type)){
          this.uiService.openSnackBar('Key already exists', undefined);
        }else{
          this.licencesService.addSvKeys(this.customerId, svKeyEdit).subscribe({
            next: () => {
              this._setSvKey();
              this.uiService.openSnackBar('Keys add', undefined);
            },
            error: () => {
              this.uiService.openSnackBar('Error adding sv Keys', undefined);
            },
            complete: () => {
              this.isLoading = false;
            }
          });
        }
      }
    });
  }

  editSvKeyDialog(svKey: SvKey): void {
    const dialogRef: MatDialogRef<EditKeyNameDialogComponent> = this.dialog.open(
      EditKeyNameDialogComponent,
      {
        minWidth: 600,
        data: svKey
      }
    );
    dialogRef.afterClosed().subscribe((svKeyEdit: SvKey) => {
      if (svKeyEdit) {
        this.licencesService.updateSvKeys(this.customerId, svKeyEdit).subscribe({
          next: () => {
            this._setSvKey();
            this.uiService.openSnackBar(uiKeyUpdate, undefined);
          },
          error: () => {
            this.uiService.openSnackBar(uiKeyUpdateError, undefined);
          },
          complete: () => {
            this.isLoading = false;
          }
        });
      }
    });
  }

  associateKeyDialog(svKey: SvKey): void {
    const dialogRef: MatDialogRef<PickLicenceDialogComponent> = this.dialog.open(
      PickLicenceDialogComponent,
      {
        minWidth: 800,
        data: {customerId: this.customerId, svKeyId: svKey.id}
      }
    );
    dialogRef.afterClosed().subscribe((associateLicence: Licence) => {
      if (associateLicence) {
        this.licencesService.associateActiveLicencesKey(this.customerId, associateLicence.id, svKey.id).subscribe({
          next: () => {
            this._setSvKey();
            this.uiService.openSnackBar(uiKeyUpdate, undefined);
          },
          error: () => {
            this.uiService.openSnackBar(uiKeyUpdateError, undefined);
          },
          complete: () => {
            this.isLoading = false;
          }
        });
      }
    });
  }

  dissociateKey(svKey: SvKey): void {
    this.licencesService.dissociateActiveLicencesKey(this.customerId, svKey.licence?.id, svKey.id).subscribe({
      next: () => {
        this._setSvKey();
        this.uiService.openSnackBar(uiKeyUpdate, undefined);
      },
      error: () => {
        this.uiService.openSnackBar(uiKeyUpdateError, undefined);
      },
      complete: () => {
        this.isLoading = false;
      }
    });
  }

  isAdmin(): boolean {
    const roleUser: Roles | undefined = this.usersService.getUserRole();
    return RoleConstants.GROUPS.ADMINS.includes(roleUser?.role);
  }

  _setActiveSvKey(): void {
    this.isLoading = true;
    this.licencesService.getActiveKeys(this.customerId).subscribe({
      next: (activeSvKey: ActiveLicence[]) => {
        this.activeKey = activeSvKey;
      },
      error: () => {
        this.uiService.openSnackBar('Error fetching Active sv Keys', undefined);
      },
      complete: () => {
        this.isLoading = false;
      }
    });
  }

  _setActiveLicences(): void {
    this.isLoading = true;
    this.licencesService.getActiveLicences(this.customerId).subscribe({
      next: (activeLicence: ActiveLicence[]) => {
        this.activeLicence = activeLicence;
      },
      error: () => {
        this.uiService.openSnackBar('Error fetching Active Licences', undefined);
      },
      complete: () => {
        this.isLoading = false;
      }
    });
  }

  _setSvKey(): void {
    this.isLoading = true;
    this.licencesService.getSvKeys(this.customerId).subscribe({
      next: (svKeys: SvKey[]) => {
        this.licencesService.getLicences(this.customerId).subscribe({
          next: (licences: Licence[]) => {
            svKeys.forEach((sv: SvKey) => {
              licences.forEach((l: Licence) => {
                if(l.svKey && sv.id === l.svKey.id){
                  sv.licence = l;
                }
              });
            });
            this.svKeys = svKeys;
            this.svKeysList = new MatTableDataSource(svKeys);
            this.svKeysList.paginator = this.paginator;
          },
          error: () => {
            this.uiService.openSnackBar('Error fetching Licences', undefined);
          },
          complete: () => {
            this.isLoading = false;
          }

        });
      },
      error: () => {
        this.uiService.openSnackBar('Error fetching sv Keys', undefined);
      },
      complete: () => {
        this.isLoading = false;
      }
    });
  }
}
