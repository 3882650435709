import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/utils/api.service';
import { environment } from '../../environments/environment';
import { CookieService as NgCookieService } from 'ngx-cookie-service';
import { User } from '@smartviser/core-lib';

@Injectable({
  providedIn: 'root'
})
export class LoginService {


  constructor(private apiService: ApiService, private http: HttpClient, private ngCookieService: NgCookieService) { }

  checkLoginForm = (username: string, password: string): Observable<any> => 
    this.http.post<any>(
      `${environment.commonBaseUrl}/authentication/v2/sessions/login`, { username, password, service: 'vws' }, this.apiService.generateAuthHeaderV2()
    );

  connectUser = (user: User): void => this.ngCookieService.set('userConnected', JSON.stringify(user));

  passwordResetRequest = (email: string): Observable<any> => 
    this.http.post<any>(
      `${environment.commonBaseUrl}/authentication/v2/requests`, { email }, this.apiService.generateAuthHeaderV1()
    );

  checkTokenPassword = (token: string, type: string): Observable<void> => this.http.get<void>(
    `${environment.commonBaseUrl}/authentication/v2/requests/${token}?type=${type}`);

  changePasswordFromToken = (token: string, password: string, passwordConfirmation: string): Observable<void> => this.http.put<void>(
    `${environment.commonBaseUrl}/authentication/v2/requests/${token}`, {
      new_password: password,
      confirm_password: passwordConfirmation,
      old_password: null
     }
  );
}
