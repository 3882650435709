import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/utils/api.service';
@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient, private apiService: ApiService) { }

  updateUser = (): Observable<any> => this.http.get<any>(
    `${environment.baseUrl}/client/v2/customers`, this.apiService.generateAuthHeaderV1());
}
