import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthorizedVersion, Licence, LicenceDate } from 'src/app/admin/interfaces/licence.interface';
import { CustomersService } from 'src/app/admin/services/customers.service';
import { LicencesService } from 'src/app/admin/services/licences.service';
import { UiService } from 'src/app/utils/ui.service';
@Component({
  selector: 'edit-licence-dialog',
  templateUrl: './edit-licence-dialog.component.html',
  styleUrls: ['./edit-licence-dialog.component.less']
})
export class EditLicenceDialogComponent implements OnInit{

  licence!: Licence;
  licences!: Licence[];
  customerId!: number;
  isEdit: boolean = false;
  authorizedV: AuthorizedVersion[] = [];


  constructor(public dialogRef: MatDialogRef<EditLicenceDialogComponent>, @Inject(MAT_DIALOG_DATA) 
              public licenceCust: {licence: Licence; customerId: number; isEdit: false; licences: Licence[]}, 
              private uiService: UiService, public licencesServices: LicencesService, public editDialog: MatDialog, public customerService: CustomersService) {
  }

  ngOnInit(): void {
    this.licence = this.licenceCust.licence;
    this.licences = this.licenceCust.licences;
    this.customerId = this.licenceCust.customerId;
    this.isEdit = this.licenceCust.isEdit;
    const today: Date = new Date();
    const endDate: Date = new Date();
    if(!this.licence && !this.licences){
      this.licence = {
        id: 0,
        startDate: today,
        endDate: new Date(endDate.setMonth(endDate.getMonth() + 1)),
        trial: false,
        synchroviser: false,
        revoked: false,
        proxy: false,
        fullLog: false,
        mos: false,
        auxCall: false,
        authorizedVersions: [],
        nbLicence: 1
      };
    }else if(this.licences){
      this.licence = this.licences[0];
      // Check if licences have the same options
      const isNotSameOptions: boolean = this.licences.some((licence: Licence) => this.licences[0].synchroviser !== licence.synchroviser || 
        this.licences[0].proxy !== licence.proxy || this.licences[0].mos !== licence.mos || this.licences[0].auxCall !== licence.auxCall);
      if(isNotSameOptions){
        this.licence.proxy = false;
        this.licence.synchroviser = false;
        this.licence.auxCall = false;
        this.licence.mos = false;
      }
    }else{
      this.licence.startDate = this.licence.startDate as LicenceDate;
      this.licence.endDate = this.licence.endDate as LicenceDate;
      this.licence.startDate = new Date(this.licence.startDate.date);
      this.licence.endDate = new Date(this.licence.endDate.date);
      this.licence.authorizedVersions = this.licence.authorizedVersions;
    }
    this.licencesServices.getVersions().subscribe({
      next: (authorizedVersions: AuthorizedVersion[]) => {
        this.authorizedV = authorizedVersions;
        if(this.licence.id === 0){
          this.licence.authorizedVersions = this.authorizedV.filter((a: AuthorizedVersion) => a.version === 'all');
        }
      },
      error: () => {
        this.uiService.openSnackBar('Error fetching version', undefined);
      }
    });
  }

  isLicenceVersion = (aVersionId: number): boolean => this.licence.authorizedVersions.some((l: AuthorizedVersion) => l.id === aVersionId);

  updateLicenceVersion = (aVersion: AuthorizedVersion) => {
    if(this.isLicenceVersion(aVersion.id)){
        const versionId: number = this.licence.authorizedVersions.findIndex((l: AuthorizedVersion) => l.id === aVersion.id);
        this.licence.authorizedVersions.splice(versionId, 1);
    }else{
        this.licence.authorizedVersions.push(aVersion);
    }
  };

}
