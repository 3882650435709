import { Component, OnChanges, OnInit } from '@angular/core';
import { SecurityService, CookieService, User, VWSServicesService, VWS_DASHBOARD, LocalStorageService} from '@smartviser/core-lib';


@Component({
  selector: 'dashboard-page',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.less']
})
export class DashboardComponent implements OnInit, OnChanges{

  allAccessibleServices: any[] = [];
  userConnected: User|null = null;

  constructor(private securityService: SecurityService, 
    private localStorageService: LocalStorageService,
    private cookieService: CookieService, 
    private vwsServicesService: VWSServicesService){
  }

  ngOnInit(): void {
    this.userConnected = this.cookieService.getUserConnected();
    const vwsServices: any = this.localStorageService.getVWSServices();
    if(this.userConnected){
      this.getUserServicesFromLocalStorageOrAPI(this.userConnected.services, vwsServices);
    }
  }

  ngOnChanges(): void{
    if(this.userConnected){
      const vwsServices: any = this.localStorageService.getVWSServices();
      this.getUserServicesFromLocalStorageOrAPI(this.userConnected.services, vwsServices);
    }
  }

  getUserServicesFromLocalStorageOrAPI(userServiceIds: unknown[], vwsServices: unknown[]): void{
    if(this.userConnected){
      if (vwsServices.length > 0) {
          this.allAccessibleServices = this.securityService.findUserServiceById(userServiceIds, vwsServices, VWS_DASHBOARD);
          return;
      }
    // if no vws services are stored locally, get them from the api
      this.vwsServicesService.find().subscribe((services: any[]): void => {
        if(this.userConnected){
          this.allAccessibleServices = this.securityService.findUserServiceById(userServiceIds, services, VWS_DASHBOARD);
        }
      }, (): void => {
          console.error('Could not get your VWS services..');
      });
    }

  }

  
}
